import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import Container from '~components/Container';
import { generateUrlFriendlyIdFromTitle } from '~utils/url-helper';
import { localeDate } from '~utils/dates';
import Breadcrumbs from '~components/Breadcrumbs';
import ArticleToc from '~components/ArticleToc';
import ContentSection from '~components/slices/article-content-box/ContentSection';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: props => props.background_color,
    position: 'relative'
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '100px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column'
    }
  },
  contentWrapper: {
    position: 'relative',
    paddingTop: '80px',
    [theme.breakpoints.down('sm')]: {
      paddingTop: '40px'
    }
  },
  parentPageLink: {
    textDecoration: 'none',
    textTransform: 'uppercase',
    color: '#1775FB',
    letterSpacing: '3px',
    fontSize: '18px',
    fontWeight: '600'
  },
  header: {
    fontSize: '52px',
    marginTop: 0,
    marginBottom: '5px',
    color: '#222222',
    fontFamily: 'Inter',
    fontWeight: '700',
    [theme.breakpoints.down('md')]: {
      marginTop: 10
    },
    [theme.breakpoints.down('xs')]: {
      fontSize: '36px'
    }
  },
  subHeader: {
    color: '#222222',
    fontSize: 18,
    fontFamily: 'Inter',
    fontWeight: '600',
    marginTop: '10px',
    marginBottom: '12px'
  },
  articleInfoText: {
    color: '#717171',
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeight: '500'
  },
  authorDateText: {
    textTransform: 'uppercase',
    letterSpacing: '1.6px'
  },
  sideNavigation: {
    position: 'absolute',
    left: '3%',
    top: 90,
    maxWidth: '260px',
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    [theme.breakpoints.down(1300)]: {
      left: '2%',
      maxWidth: '240px'
    },
    [theme.breakpoints.down(1200)]: {
      display: 'none'
    }
  }
}));

const ArticleContent = ({
  article_title,
  article_subtitle,
  article_author,
  article_date,
  read_time,
  background_color,
  contentBlocks,
  pageBreadcrumbs,
  toc,
  containerSize
}) => {
  const location = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const isBelowMediumViewPort = useMediaQuery(theme.breakpoints.down(1200), { noSsr: true });
  const dateOptions = isMobile
    ? {
        year: 'numeric',
        month: 'short',
        day: 'numeric'
      }
    : {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
      };

  const classes = useStyles({ background_color });
  const sectionIds = useMemo(
    () =>
      contentBlocks.map(section =>
        generateUrlFriendlyIdFromTitle(section.text_block_title_rich_text?.text)
      ),
    [contentBlocks]
  );

  useEffect(() => {
    // expand when url hash has a section id
    if (location?.hash && sectionIds.indexOf(location.hash.substring(1)) > -1) {
      navigate(location?.hash);
    }
  }, [location?.hash, sectionIds]);

  return (
    <>
      <div className={classes.root}>
        <div className={classes.sideNavigation}>
          <Breadcrumbs pageBreadcrumbs={pageBreadcrumbs} fontSize="14px" />
          <ArticleToc toc={toc} />
        </div>
        <Container maxWidth={containerSize}>
          <div className={classes.contentContainer}>
            <div>
              <div className={classes.contentWrapper}>
                {isBelowMediumViewPort && (
                  <Breadcrumbs pageBreadcrumbs={pageBreadcrumbs} fontSize="14px" />
                )}
                <h1 className={classes.header}>{article_title.text}</h1>
                <h2 className={classes.subHeader}>{article_subtitle.text}</h2>
                <Typography className={classes.articleInfoText}>
                  {article_author && (
                    <span className={classes.authorDateText}>BY {article_author}</span>
                  )}
                  {article_date && (
                    <span className={classes.authorDateText}>
                      {' '}
                      | {localeDate(article_date, dateOptions)}
                    </span>
                  )}
                  {read_time > 0 && <span> | {read_time} Minute Read</span>}
                </Typography>
                {contentBlocks.map((section, index) => (
                  <ContentSection
                    key={sectionIds[index]}
                    id={sectionIds[index]}
                    title={section.text_block_title_rich_text?.html}
                    content={section.text_block_content_rich_text?.html}
                    image={section.content_image}
                  />
                ))}
              </div>
            </div>
          </div>
        </Container>
      </div>
    </>
  );
};

ArticleContent.propTypes = {
  article_title: PropTypes.shape({
    text: PropTypes.string.isRequired
  }).isRequired,
  article_subtitle: PropTypes.shape({
    text: PropTypes.string.isRequired
  }).isRequired,
  article_author: PropTypes.string.isRequired,
  article_date: PropTypes.string.isRequired,
  read_time: PropTypes.number.isRequired,
  background_color: PropTypes.string.isRequired,
  contentBlocks: PropTypes.arrayOf(
    PropTypes.shape({
      text_block_title_rich_text: PropTypes.shape({
        text: PropTypes.string.isRequired,
        html: PropTypes.string.isRequired
      }),
      text_block_content_rich_text: PropTypes.shape({
        text: PropTypes.string.isRequired,
        html: PropTypes.string.isRequired
      }).isRequired
    }).isRequired
  ).isRequired,
  pageBreadcrumbs: PropTypes.shape({
    currentTitle: PropTypes.string,
    parentUrl: PropTypes.string,
    parentTitle: PropTypes.string,
    parentType: PropTypes.string
  }),
  toc: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  ),
  containerSize: PropTypes.string
};

ArticleContent.defaultProps = {
  pageBreadcrumbs: {},
  toc: [],
  containerSize: 'md'
};

export default ArticleContent;
