import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Link from '~components/Link';

const useStyles = makeStyles({
  root: {
    padding: '0px',
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    gap: 6
  },
  activeLink: {
    color: '#1775FB',
    fontSize: props => props.fontSize,
    fontFamily: 'Inter',
    fontWeight: '500'
  },
  disabledLink: {
    color: '#717171',
    fontSize: props => props.fontSize,
    fontFamily: 'Inter',
    fontWeight: '500',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    lineClamp: 1,
    WebkitBoxOrient: 'vertical'
  },
  separator: {
    color: '#1775FB',
    fontSize: props => props.fontSize,
    marginTop: 0,
    marginBottom: 0
  }
});

const Breadcrumbs = ({ pageBreadcrumbs, fontSize }) => {
  const classes = useStyles({ fontSize });
  const breadcrumbs = useMemo(() => {
    const items = [];
    if (pageBreadcrumbs.parentType === 'parshat') {
      items.push({ url: '/weekly-torah-portion', title: 'Parsha' });
    }
    if (pageBreadcrumbs?.parentUrl) {
      items.push({ url: pageBreadcrumbs?.parentUrl, title: pageBreadcrumbs?.parentTitle });
    }
    if (pageBreadcrumbs?.currentTitle) {
      items.push({ url: '', title: pageBreadcrumbs?.currentTitle });
    }
    return items;
  }, [pageBreadcrumbs]);

  return (
    <div className={classes.root}>
      {breadcrumbs.map((item, index) => (
        <React.Fragment key={`breadcrumb-${item.title}`}>
          <Link
            to={item.url || '#'}
            className={item.url ? classes.activeLink : classes.disabledLink}
            underline={item.url ? 'hover' : 'none'}
          >
            {item.title}
          </Link>
          {index !== breadcrumbs?.length - 1 && <p className={classes.separator}>{'>'}</p>}
        </React.Fragment>
      ))}
    </div>
  );
};

Breadcrumbs.propTypes = {
  pageBreadcrumbs: PropTypes.shape({
    currentTitle: PropTypes.string,
    parentUrl: PropTypes.string,
    parentTitle: PropTypes.string,
    parentType: PropTypes.string
  }),
  fontSize: PropTypes.string
};

Breadcrumbs.defaultProps = {
  pageBreadcrumbs: {},
  fontSize: '18px'
};

export default Breadcrumbs;
