import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Link from '~components/Link';

const useStyles = makeStyles({
  root: {
    padding: '0px',
    position: 'relative',
    width: '100%'
  },
  linkContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 8
  },
  title: {
    color: '#222222',
    fontSize: 16,
    fontFamily: 'Inter',
    fontWeight: '700',
    marginTop: 0,
    marginBottom: 8
  },
  activeLink: {
    color: '#222222',
    fontSize: 14,
    fontFamily: 'Inter',
    fontWeight: '400',
    lineHeight: '170%'
  }
});

const ArticleToc = ({ toc }) => {
  const classes = useStyles();

  if (!toc || toc?.length === 0) {
    return null;
  }

  return (
    <div className={classes.root}>
      <h4 className={classes.title}>Table of Contents</h4>
      <div className={classes.linkContainer}>
        {toc.map(item => (
          <Link key={item.title} to={item.url} className={classes.activeLink} underline="hover">
            {item.title}
          </Link>
        ))}
      </div>
    </div>
  );
};

ArticleToc.propTypes = {
  toc: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      url: PropTypes.string
    })
  )
};

ArticleToc.defaultProps = {
  toc: []
};

export default ArticleToc;
